/* SERVICES */

.services{
    color: #082F43;
    text-align: left;
  }
  
  .services h3 {
    width: 63%;
    font-size: 2.15rem;
    font-weight: 600;
    margin-top: 6rem;
    margin-left: 7.5vw;
    margin-bottom: 10rem;
  }
  
  .servicesView {
    width: 70%;
    margin: 6rem auto;
    display: flex;
    flex-direction: column;
  }
  
  .serviceComponent {
    width: 40rem;
    background-color: #F7FFFD;
    overflow: auto;
    padding-bottom: 4rem;
    padding-top: 2rem;
    box-shadow: 1px 4px 4px rgb(0,0,0,0.3);
    margin-top: -4rem;
  }
  
  .serviceComponent h2 {
    width: 66%;
    font-size: 2.5rem;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  
  .serviceComponent p {
    width: 66%;
    font-size: 1.5rem;
    margin: 0.5rem auto;
    font-weight: 600;
  }
  
  .align-right {
    margin-left: auto;
  }
  
  .align-left {
    margin-right: auto;
  }
  
  .services .nextSection {
    color: #082F43;
  }
.App {
  text-align: center;
}

a {
  text-decoration: none;
}

.colorDiv {
  width: 100vw; /* Max width */
  min-height: 100vh; /* The viewport height */
  max-width: 100%; /* Ensures it does not exceed the width of its parent */
  overflow: auto;
}

.heroSection {
  background-color: #454745;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.services {
  background-color: #E7CBB4;
}

.works {
  background-color: #F283B6;
}

.contact {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.breadcrumb {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  z-index: 1000; /* Ensure it's above other content */
  opacity: 0.9;
}


/* HERO */

.heroContent {
    /* Center the content */
    display: flex;
    flex-direction: row;
    max-width: 70rem;
    margin: auto;
    justify-content: space-between;
    color: #F7FFFD;
    text-align: left;
  }
  
  .heroContent p {
    color: #D6CAD0;
    line-height: 2rem;
    font-size: x-large;
    letter-spacing: 0.3rem;
    width: 95%;
    margin: 0
  }
  
  .heroContent h1 {
    font-size:3rem;
    letter-spacing: 0.2rem;
    width: 80%;
    margin: 0.7rem
  }
  
  .heroContent span {
    color: #BF8461
  }
  
  .heroImageContainer {
    /* Style for the profile image */
    min-width: 25rem; /* Adjust as needed */
    min-height: 25rem;
    max-width: 25rem;
  }

  .heroImageContainer img {
    /* Style for the profile image */
    max-width: 100%;
  }

  
  .contactButton {
    /* Style for the 'Get in touch!' button */
    background-color: #C4646A; /* Adjust as needed */
    color: white;
    border: none;
    padding: 0.6rem 2rem;
    border-radius: 3rem; /* Rounded corners */
    cursor: pointer;
    font-size: 2rem;
    margin-top: 2rem; /* Space above the button */
    font-weight: 700;
    box-shadow: 5px 4px 5px rgb(0,0,0,0.4);
  }
  
  .contactButton a {
    text-decoration: none;
    color: white;
  }
  
  .nextSection {
    color: #F7FFFD;
    font-family: 'Josefin Sans', sans-serif;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    justify-content: center;
  }
/* PROJECTS */

.projectComponent {
    max-width: 65vw;
    margin: 16rem  auto;
    text-align: left;
    display: flex;
    flex-direction: row;
    gap:1rem;
    justify-content: space-between;
  }
  
  .projectComponent h1 {
    font-family: 'Anonymous Pro', monospace;
    font-size: larger;
  }
  
  .projectComponent span {
    text-decoration: underline;
  }
  
  .projectComponent h2 {
    font-family: 'Roboto Mono', monospace;
    font-size: small;
    background-color: rgb(225,197,150,0.7);
    padding: 0.4rem
  }
  
  .projectInfo {
    max-width: 45%;
  }
  
  .projectInfo img {
    height: 6rem;
    margin-top: 1rem;
  }
  
  .projectComponent p {
    font-family: 'Allerta', monospace;
    font-size: 1.4rem;
    line-height: 2.3rem;
  }
  
  .projectComponent button {
    /* Style for the 'Get in touch!' button */
    font-family: 'Roboto Mono', monospace;
    background-color: #5D605D; /* Adjust as needed */
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 0.8rem 2rem;
  }
  
  .projectImg {
    height: 30rem;
    min-width: 30rem;
    border-radius: 2rem;
    object-fit: contain;
    overflow: clip;
  }
  
  .projectImg img {
    max-height: 30rem;
    min-width: 30rem;
    object-fit: cover;
    border-radius: 2rem;
  }
  
  .works .nextSection{
    color:#082F43;
  }
  
  .tags { 
    display: flex;
    flex-direction: row;
    gap: 1rem
  }
@media (max-width: 70rem) { /* Adjust breakpoint as needed */
    html {
        font-size: 14px;
    }
    .heroContent {
      flex-direction: column;
      align-items: center; /* Center the content vertically */
      margin: 0 1rem;
      align-items: center;
      text-align: center;
      max-width: 50rem;
    }

    .heroIntro h1{ 
        margin: 0 auto;
        margin-bottom: 0.7rem;
    }
  
    .heroImageContainer {
      margin-top: 10vh;
      order: -1;
      width: 100%; /* Full width of the container */
      max-width: 25rem; /* Maximum width to maintain aspect ratio */
    }
  
    .nav {
      gap: 1rem;
      font-size: 0.9rem;
    }

    .nav .icons {
      margin-left: 2rem;
    }
  
    .servicesView{
      width: 90%;
      margin: 1rem auto
    }
  
    .serviceComponent { 
      padding: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: 75vw;
      margin-bottom: 3rem;
    }
  
    .serviceComponent p{
      width: 90%;
    }
    .serviceComponent h2{
      width: 90%;
    }
    .projectComponent {
      text-align: center;
      align-items: center;
      max-width: 90%;
      flex-direction: column;
      margin: 20em auto;
    }
    .projectInfo { 
      width: 100%;
      max-width: 35rem;
    }
  
    .tags {
      justify-content: center;
    }
  
    .projectImg {
      justify-content: center;
      max-width: 90vw;
      order: -1
    }
    .projectImg {
      min-width: 15rem; /* Fixed width of the container */
      height: 25rem; /* Fixed height of the container */
      overflow: hidden; /* Ensures any excess image is cropped */
      display: flex; /* Ensures content is centered if using flexbox */
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
    }
    
    .projectImg img {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Cover the area of the container */
      object-position: center; /* Center the image within the container */
    }

    .contactView { 
      flex-direction: column;
      align-items: center;
      max-width: 96%;
      margin-top: 8rem;
      gap: 0;
    }

    .contactPrompt p{
      max-width: max-content;
      max-width: 60rem;
    }

    
  }

  @media (max-width: 500px) {
    html {
        font-size: 10px;
    }
    nav {
      gap: 0.5rem
    }
    .heroIntro h1 {
        font-size: x-large;
        line-height: 3rem;
    }
    .heroIntro p {
        width: 100%;
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    .contactInfo {
      flex-direction: column;
    }

    .contactForm {
      width: 100%
    }
    .contactForm label,
    .contactForm select,
    .contactForm input,
    .contactForm textarea {
      font-size: 1.5rem; /* Set the font size larger as needed */
      /* Other styles */
    }
  }
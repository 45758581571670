/* CONTACT */

.contactView { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    margin: 0 auto;
    margin-top: 10rem;
    gap: 8rem;
  }
  
  .contactInfo { 
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 2rem;
    margin: 0 auto;
  }

  .contactInfo h3 {
    font-size: large;
    font-weight: normal; /* Make text not bold */
    cursor: pointer; /* Change cursor to pointer on hover */
    position: relative; /* Needed to position the hover message correctly */
    margin: 1rem;
  }
  
  .contactPrompt h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-top: 0;
  }
  
  .contactPrompt p{
    font-size: 2.1rem;
    letter-spacing: 0.06em;
    font-weight: 450;
    width: 100%;
    line-height: 120%;
    max-width: 25rem
  }
  
  .contactForm {
    display: flex;
    flex-direction: column;
    width: 30rem;
  }
  
  .contactForm label,
  .contactForm select,
  .contactForm input,
  .contactForm textarea {
    font-size: 1.1rem; /* Set the font size larger as needed */
    /* Other styles */
  }
  
  .contactForm input,
  .contactForm select,
  .contactForm textarea {
    margin-bottom: 20px; /* Spacing between fields */
    padding: 10px;
    border: 1px solid #ccc; /* Border color */
    border-radius: 5px; /* Rounded corners for inputs */
  }
  
  .contactForm textarea {
    min-height: 6rem; /* Adjust the height as needed */
    resize: vertical;
  }
  
  .submitBtn {
    background-color: #424BF5; /* Your button color */
    color: white;
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: bold;
  }
  
  .contactForm label {
    display: block;
    margin-bottom: 0.4rem;
  }
/* HEADER */

.logo img {
    height: 3rem; /* Adjust as per your logo's aspect ratio */
  }
  
  .nav, .icons{
    display: flex;
    gap: 3rem; /* This adds space between your navigation links */
    justify-content: center;
    align-items: center;
  }
  
  .nav a {
    color: #D6CAD0; /* Set text color for links */
    font-size: larger;
    font-weight: 500; /* Optional: if you want bold text for links */      
  }
  
  .nav img{
    height: 2rem;
  }
  
  .header {
    position: -webkit-sticky; /* Safari */
    position: fixed;
    top: 0;
    background-color: rgba(69, 71, 69, 1);
    z-index: 1000; /* Ensures the header stays on top of other elements */
    /* Other styles (flexbox, padding, etc.) */
    width: 100%;
  }
  
  .headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    width: 90rem;
    margin: 0 auto;
    max-width: 90%;
  }